/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Confirm, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import style from './assessmentFooter.style';
import { getNextStatuses, getPreviousStatuses, Status, STATUS, StatusCode } from '../model/assessmentStatus';
import { selectPrincipal } from '../../auth/store/principalSlice';
import {
  selectIsAuditorOwner,
  selectIsBrandCoordinator,
  updateFollowUpStatus,
  updateStatus,
} from '../store/assessmentDetailSlice';
import {
  areAllTopicsValid,
  AssessmentDetail,
  checkFollowUpsValidity,
  FOLLOW_UP_ERROR_TYPE,
  FollowUpStatus,
  getActionPlanSubProcessesWithError,
  getMergingSubProcessesWithError,
} from '../model/assessmentDetail.model';
import { ModalPage } from '../../../shared/ModalPage';
import { AuditFixList } from './AuditFixList';
import { toastService } from '../../../core/services/toastService';
import { useAppDispatch } from '../../../core/store';
import { getNextFollowUpStatus } from '../model/assessmentFollowUp.model';
import { checkRole, ROLES } from '../../auth/model/principal.model';

interface AssessmentFooterProps {
  assessment: AssessmentDetail;
}

export const AssessmentFooter = ({ assessment }: AssessmentFooterProps): JSX.Element => {
  const user = useSelector(selectPrincipal);
  const isAuditorOwner = useSelector(selectIsAuditorOwner);
  const isBrandCoordinator = useSelector(selectIsBrandCoordinator);
  const isAuditAdmin = useMemo(() => checkRole(user, ROLES.AUDIT_ADMIN), [user]);
  const isRegionalManagerApprovalRequired = useMemo(
    () => assessment.auditors.every(auditor => auditor.approvalRequired),
    [assessment.auditors]
  );
  const isRegionalManager = useMemo(
    () => user.email === assessment.regionalManager?.email,
    [assessment.regionalManager?.email, user.email]
  );
  const previousStatuses = getPreviousStatuses(
    assessment.status.code,
    user,
    isAuditorOwner,
    isBrandCoordinator,
    isRegionalManagerApprovalRequired,
    isRegionalManager
  );
  const nextStatuses = getNextStatuses(
    assessment.status.code,
    user,
    isAuditorOwner,
    isBrandCoordinator,
    isRegionalManagerApprovalRequired,
    isRegionalManager
  );
  const nextFollowUpStatus = getNextFollowUpStatus(
    assessment.followUpStatus,
    isAuditorOwner,
    isBrandCoordinator,
    isAuditAdmin
  );

  switch (assessment.status.code) {
    case STATUS.MERGING.code:
      return <MergingFooter assessment={assessment} previousStatuses={previousStatuses} nextStatuses={nextStatuses} />;
    case STATUS.SENT_TO_THE_STORES.code:
      return (
        <SentToTheStoresFooter
          assessment={assessment}
          previousStatuses={previousStatuses}
          nextStatuses={nextStatuses}
        />
      );
    case STATUS.CLOSED.code:
      return (
        <ClosedFooter
          assessment={assessment}
          previousStatuses={previousStatuses}
          nextFollowUpStatus={nextFollowUpStatus}
        />
      );
    default:
      return <Footer assessment={assessment} previousStatuses={previousStatuses} nextStatuses={nextStatuses} />;
  }
};

interface FooterProps {
  assessment: AssessmentDetail;
  previousStatuses: Status[];
  nextStatuses: Status[];
}

const Footer = ({ assessment, previousStatuses, nextStatuses }: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  const showFooter = useCallback(
    () => previousStatuses.length > 0 || nextStatuses.length > 0,
    [nextStatuses, previousStatuses]
  );
  const [isInfoWarningOpen, setInfoWarningOpen] = useState(false);

  const onClickNextStatus = useMemo(
    () =>
      assessment.status.code === 'APPROVED_TO_BE_SENT' &&
      (assessment.brandCoordinatorList == null || assessment.brandCoordinatorList.length === 0)
        ? () => setInfoWarningOpen(true)
        : undefined,
    [assessment]
  );

  return showFooter() ? (
    <div css={style.footer}>
      <div css={style.buttonLeftContainer}>
        {previousStatuses.map(s => (
          <GoToStatusButton assessment={assessment} statusCode={s.code} type='back' key={`button-${s.code}`} />
        ))}
      </div>
      <div css={style.buttonRightContainer}>
        {nextStatuses.map(s => (
          <GoToStatusButton
            assessment={assessment}
            statusCode={s.code}
            type='forward'
            onClick={onClickNextStatus}
            key={`button-${s.code}`}
          />
        ))}
      </div>
      <Confirm
        css={style.modalConfirm}
        open={isInfoWarningOpen}
        onConfirm={() => {
          setInfoWarningOpen(false);
        }}
        header={
          <div css={style.warningConfirmHeader}>
            <Icon name='exclamation circle' />
            <span>{t('assessment.audit.warning')}</span>
          </div>
        }
        cancelButton={<></>}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        content={t('assessment.brandCoordinatorIsMandatory')}
        size='mini'
      />
    </div>
  ) : (
    <></>
  );
};

const MergingFooter = ({ assessment, previousStatuses, nextStatuses }: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  const [isAuditFixListOpen, setAuditFixListOpen] = useState(false);
  const [isInfoWarningOpen, setInfoWarningOpen] = useState(false);

  const showFooter = useCallback(
    () => previousStatuses.length > 0 || nextStatuses.length > 0,
    [nextStatuses, previousStatuses]
  );

  const getSubProcessesWithError = useCallback(
    () => getMergingSubProcessesWithError(assessment.audit.processes),
    [assessment.audit.processes]
  );

  const onClickNextStatus = useMemo(
    () =>
      assessment.status.code === 'MERGING' &&
      (((assessment.store.storeManager.name == null || assessment.store.storeManager.name.trim() === '') &&
        (assessment.store.storeManager.email == null || assessment.store.storeManager.email.trim() === '')) ||
        assessment.storeManagerSince == null ||
        assessment.storeManagerSince.trim() === '') &&
      !assessment.storeManagerNotAvailable
        ? () => setInfoWarningOpen(true)
        : undefined,
    [assessment]
  );

  return showFooter() ? (
    isAuditFixListOpen ? (
      <ModalPage
        onClose={() => setAuditFixListOpen(false)}
        title={`${t('assessment.audit.fixListTitle')} ${
          getSubProcessesWithError().length > 1 ? `(${getSubProcessesWithError().length} left)` : ''
        } `}>
        <AuditFixList subProcesses={getSubProcessesWithError()} onClose={() => setAuditFixListOpen(false)} />
      </ModalPage>
    ) : (
      <div css={style.footer}>
        <div css={style.buttonLeftContainer}>
          {previousStatuses.map(s => (
            <GoToStatusButton assessment={assessment} statusCode={s.code} type='back' key={`button-${s.code}`} />
          ))}
        </div>
        <div css={style.buttonRightContainer}>
          {nextStatuses.map(s =>
            getSubProcessesWithError().length > 0 ? (
              <Button
                key={`button-${s.code}`}
                css={style.button}
                icon
                labelPosition='right'
                onClick={() => setAuditFixListOpen(true)}>
                {t(`assessment.fix`)} ({getSubProcessesWithError().length})
                <Icon name='exclamation circle' />
              </Button>
            ) : (
              <GoToStatusButton
                assessment={assessment}
                statusCode={s.code}
                type='forward'
                key={`button-${s.code}`}
                onClick={onClickNextStatus}
              />
            )
          )}
        </div>
        <Confirm
          css={style.modalConfirm}
          open={isInfoWarningOpen}
          onConfirm={() => {
            setInfoWarningOpen(false);
          }}
          header={
            <div css={style.warningConfirmHeader}>
              <Icon name='exclamation circle' />
              <span>{t('assessment.audit.warning')}</span>
            </div>
          }
          cancelButton={<></>}
          confirmButton={<Button css={style.confirmButton}>Ok</Button>}
          content={t('assessment.StoreManagerAndSmDateAreMandatory')}
          size='mini'
        />
      </div>
    )
  ) : (
    <></>
  );
};

const SentToTheStoresFooter = ({ assessment, previousStatuses, nextStatuses }: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  const [isInfoWarningOpen, setInfoWarningOpen] = useState(false);
  const showFooter = useCallback(
    () => previousStatuses.length > 0 || nextStatuses.length > 0,
    [nextStatuses, previousStatuses]
  );
  const getSubProcessesWithError = useCallback(
    () => getActionPlanSubProcessesWithError(assessment.audit.processes),
    [assessment.audit.processes]
  );

  const onClickNextStatus = useMemo(
    () =>
      getSubProcessesWithError().length > 0 || !areAllTopicsValid(assessment)
        ? () => setInfoWarningOpen(true)
        : undefined,
    [getSubProcessesWithError, assessment]
  );

  return showFooter() ? (
    <div css={style.footer}>
      <div css={style.buttonLeftContainer}>
        {previousStatuses.map(s => (
          <GoToStatusButton assessment={assessment} statusCode={s.code} type='back' key={`button-${s.code}`} />
        ))}
      </div>

      <div css={style.buttonRightContainer}>
        {nextStatuses.map(s => (
          <GoToStatusButton
            assessment={assessment}
            statusCode={s.code}
            type='forward'
            onClick={onClickNextStatus}
            key={`button-${s.code}`}
          />
        ))}
        <Confirm
          css={style.modalConfirm}
          open={isInfoWarningOpen}
          onConfirm={() => {
            setInfoWarningOpen(false);
          }}
          header={
            <div css={style.warningConfirmHeader}>
              <Icon name='exclamation circle' />
              <span>{t('assessment.audit.warning')}</span>
            </div>
          }
          cancelButton={<></>}
          confirmButton={<Button css={style.confirmButton}>Ok</Button>}
          content={t('assessment.audit.actionPlanWarning')}
          size='mini'
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

interface ClosedFooterProps {
  assessment: AssessmentDetail;
  previousStatuses: Status[];
  nextFollowUpStatus: FollowUpStatus | null;
}

const ClosedFooter = ({ assessment, previousStatuses, nextFollowUpStatus }: ClosedFooterProps): JSX.Element => {
  const showFooter = useCallback(
    () => previousStatuses.length > 0 || nextFollowUpStatus != null,
    [previousStatuses, nextFollowUpStatus]
  );
  const { t } = useTranslation();
  const [isInfoWarningOpen, setInfoWarningOpen] = useState(false);

  const onClickNextStatus = useMemo(() => {
    switch (assessment.followUpStatus) {
      case FollowUpStatus.UNDER_FOLLOWUP:
        return checkFollowUpsValidity(assessment).length === 0 ? undefined : () => setInfoWarningOpen(true);
      default:
        return undefined;
    }
  }, [assessment]);

  return showFooter() ? (
    <div css={style.footer}>
      <div css={style.buttonLeftContainer}>
        {previousStatuses.map(s => (
          <GoToStatusButton assessment={assessment} statusCode={s.code} type='back' key={`button-${s.code}`} />
        ))}
      </div>
      <div css={style.buttonRightContainer}>
        {nextFollowUpStatus && (
          <GoToFollowUpStatusButton
            assessment={assessment}
            followUpStatus={nextFollowUpStatus}
            onClick={onClickNextStatus}
          />
        )}
        <Confirm
          css={style.modalConfirm}
          open={isInfoWarningOpen}
          onConfirm={() => {
            setInfoWarningOpen(false);
          }}
          header={
            <div css={style.warningConfirmHeader}>
              <Icon name='exclamation circle' />
              <span>{t('assessment.audit.warning')}</span>
            </div>
          }
          cancelButton={<></>}
          confirmButton={<Button css={style.confirmButton}>Ok</Button>}
          content={
            <div css={style.warningConfirmContent}>
              {checkFollowUpsValidity(assessment).filter(error => error.errorType === FOLLOW_UP_ERROR_TYPE.INCOMPLETE)
                .length > 0 && <div>{t('assessment.audit.followUpNotCompletedWarning')}</div>}
              {checkFollowUpsValidity(assessment).filter(error => error.errorType === FOLLOW_UP_ERROR_TYPE.EXPIRED)
                .length > 0 && (
                <>
                  <br />
                  <div>{t('assessment.audit.followUpExpiredWarning')}</div>
                  <ul>
                    {checkFollowUpsValidity(assessment)
                      .filter(error => error.errorType === FOLLOW_UP_ERROR_TYPE.EXPIRED)
                      .map(error => {
                        return <li>{error.subProcessCode}</li>;
                      })}
                  </ul>
                </>
              )}
            </div>
          }
          size='mini'
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

interface GoToStatusButtonProps {
  assessment: AssessmentDetail;
  statusCode: StatusCode;
  type: 'back' | 'forward';
  onClick?: () => void;
}

const GoToStatusButton = ({ assessment, statusCode, type, onClick }: GoToStatusButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const goToStatus = useCallback(async () => {
    const response = await dispatch(updateStatus(assessment.id, statusCode));
    if (response != null) toastService.success();
  }, [assessment.id, dispatch, statusCode]);

  const onButtonClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      setConfirmIsOpen(true);
    }
  }, [onClick]);

  const label = useMemo(() => {
    const action = type === 'back' ? assessment.status.backAction : assessment.status.forwardAction;
    return action != null ? t(`assessment.statusAction.${action[statusCode]}`) : '';
  }, [assessment.status, statusCode, t, type]);

  return (
    <>
      <Button css={style.button} icon labelPosition={type === 'back' ? 'left' : 'right'} onClick={onButtonClick}>
        <Icon name={type === 'back' ? 'backward' : 'forward'} />
        {label}
      </Button>
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        onCancel={() => setConfirmIsOpen(false)}
        onConfirm={() => {
          setConfirmIsOpen(false);
          goToStatus();
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </>
  );
};

interface GoToFollowUpStatusButtonProps {
  assessment: AssessmentDetail;
  followUpStatus: FollowUpStatus;
  onClick?: () => void;
}

const GoToFollowUpStatusButton = ({
  assessment,
  followUpStatus,
  onClick,
}: GoToFollowUpStatusButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const goToFollowUpStatus = useCallback(async () => {
    const response = await dispatch(updateFollowUpStatus(assessment.id, followUpStatus, assessment.followUpStatus));
    if (response != null) toastService.success();
  }, [assessment.id, dispatch, followUpStatus, assessment.followUpStatus]);

  const onButtonClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      setConfirmIsOpen(true);
    }
  }, [onClick]);

  return (
    <>
      <Button css={style.button} icon labelPosition='right' onClick={onButtonClick}>
        <Icon name='forward' />
        {t(`assessment.followUpAction.${followUpStatus}`)}
      </Button>
      <Confirm
        css={style.modalConfirm}
        open={confirmIsOpen}
        onCancel={() => setConfirmIsOpen(false)}
        onConfirm={() => {
          setConfirmIsOpen(false);
          goToFollowUpStatus();
        }}
        confirmButton={<Button css={style.confirmButton}>Ok</Button>}
        size='mini'
      />
    </>
  );
};
